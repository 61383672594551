import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, CardMedia, CardActionArea, CardContent, Typography, Box, Button, CardActions } from "@mui/material";
import { Edit, Delete as DeleteIcon } from "@mui/icons-material";

interface CardProps {
  card: {
    id: string;
    title: string;
    link: string;
  };
  hasEditAccess: boolean;
  isBigScreen: boolean;
  handleOpen: (link: string, title: string, id: string) => void;
  setCardID: (id: string) => void;
  deleteTask: (event: React.MouseEvent, id: string, link: string, title: string) => void;
  id: string | undefined;
}

const BAPdfCard: React.FC<CardProps> = ({
  card,
  hasEditAccess,
  isBigScreen,
  handleOpen,
  setCardID,
  deleteTask,
  id,
}) => {
  const navigate = useNavigate();
  const pdfIcon = require("../../../assets/icons/pdf-file-9-128.jpeg");

  return (
    <Card
      key={card.id}
      sx={{
        width: "100%",
        display: "flex",
        marginY: "10px",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(`/paper/${card.id}/${id}/?page=1`);
      }}
    >
      {isBigScreen ? (
        <CardMedia component="img" sx={{ width: 150, transform: "scale(0.5)" }} image={pdfIcon} alt="PDF icon" />
      ) : (
        <></>
      )}

      <CardActionArea>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant={isBigScreen ? "h5" : "h6"}>
              {card.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {card.link}
            </Typography>
          </CardContent>

          {hasEditAccess ? (
            <CardActions>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpen(card.link, card.title, card.id);
                  setCardID(card.id);
                }}
              >
                <Edit /> Edit
              </Button>
              <Button
                size="small"
                color="primary"
                style={{ position: "relative", right: "0" }}
                onClick={(event) => deleteTask(event, card.id, card.link, card.title)}
              >
                <DeleteIcon />
                <Typography>Delete</Typography>
              </Button>
            </CardActions>
          ) : (
            <></>
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default BAPdfCard;
