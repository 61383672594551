import { Box, Grid, Grow, useMediaQuery } from "@mui/material";
import CardMyAssignment from "../../components/Card/CardMyAssignment";
import EmptyState from "../../components/EmptyState/EmptyState";

const MyAssignments = ({ props }) => {
  const isBigScreen = useMediaQuery("(min-width: 600px)");

  const isEmptyRecentBoards = (): boolean => {
    if (!props.classrooms) {
      return true;
    }
    if (props.classrooms && props.classrooms.length === 0) {
      return true;
    }
    return false;
  };

  const filteredData = props.classrooms?.classroom_id?.filter((el) => {
    //if no input the return the original
    if (props.input === "") {
      return el;
    }
    //return the item which contains the user input
    else {
      return el.name.toLowerCase().includes(props.input);
    }
  });

  return (
    <Box style={{ paddingBottom: "5%", paddingTop: "0%" }}>
      <Grid container spacing={0} style={{ display: "flex" }}>
        <Grid item={true} xs={isBigScreen ? 12 : 12}>
          <Grow style={{ paddingTop: "20px", minHeight: "300px" }} in={true} timeout={1000}>
            <div>
              {isEmptyRecentBoards() ? (
                <EmptyState message="No Classroom or Assignments found!" />
              ) : (
                <div className="outer-card-cont">
                  {props?.classrooms?.map((classroom) => (
                    <CardMyAssignment classroom={classroom} key={classroom.classroom_id} />
                  ))}
                </div>
              )}
            </div>
          </Grow>
        </Grid>
      </Grid>
    </Box>
  );
};
export default MyAssignments;
