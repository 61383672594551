import React from "react";
import { ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";

const PermLists = ({ editAccessUsers, checked, handleToggle, isAdmin }) => {
  return (
    <>
      {editAccessUsers.map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem key={value}>
            <ListItemButton role={undefined} onClick={handleToggle(editAccessUsers.indexOf(value))} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(editAccessUsers.indexOf(value)) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
              {isAdmin ? <Edit /> : <Visibility />}
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
};

export default PermLists;
