import React from 'react';
import { RoadmapComponent } from '../../components/Roadmaps/Roadmap';
import './RoadmapPage.css';

export function RoadmapPage() {
  return (
    <div>
      <RoadmapComponent />
    </div>
  );
}
