import { TextField } from "@mui/material";
import { Box, Button, CircularProgress, Backdrop } from "@mui/material";
import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesPage } from "../../../service/config";
import { NewRoadmap } from "../../../types/roadmap";
import "./CreateRoadmap.css";
import ChipInputAutosuggest from "./ChipInputAutosuggest";
import axios from "axios";
import Collapse from "@mui/material/Collapse";
import { auth } from "../../../firebase";
import { apiLink } from "../../../default";

export const CreateRoadmap = () => {
  const [loading, setLoading] = useState(false);

  const history = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const [roadmapName, setRoadmapName] = React.useState("");
  const [roadmapType, setRoadmapType] = React.useState("adult");
  const [createdBy, setCreatedBy] = useState("author");
  const [createdByEmail, setCreatedByEmail] = useState("author");
  const [levels, setLevels] = useState(1);
  const [tags, setTags] = useState<any>([]);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("New roadmap");
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSubmit = async (event: FormEvent) => {
    setLoading(true);
    event.preventDefault();
    const isAdmin = localStorage.getItem("isAdmin");
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      let setPublic = true;
      if (isAdmin === "true" || localStorage.getItem("teacher_id") !== undefined) {
        setPublic = false;
      }

      const postData: NewRoadmap = {
        name: roadmapName,
        levels: levels,
        tags: tags,
        description: description,
        rating: rating,
        author: createdBy,
        email: createdByEmail,
        public: setPublic,
        roadMapType: roadmapType,
      };

      const response = await axios.post(`${apiLink}/roadmap/`, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        if (response.status === 200) {
          console.log(` You have created: ${JSON.stringify(response.data)}`);
          history(`${RoutesPage.boards}/${response.data.uid}`, { state: { hasEditAccess: true } });
        } else {
          throw new Error("An error has occurred");
        }
      } catch (error) {
        console.log("An error has occurred");
      }
      // continue with the rest of the code
    }
  };

  return (
    <Box style={{ marginTop: "30px", marginBottom: "10px" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <TextField
          label="Roadmap Name*"
          variant="filled"
          style={{ width: 350 }}
          defaultValue={roadmapName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRoadmapName(event.target.value)}
        />
      </Collapse>
      {/* <ChipInputAutosuggest data={suggestions} /> */}
      <Button
        variant="contained"
        sx={{ width: "200px", fontSize: "16px", marginTop: "10px" }}
        onClick={roadmapName ? handleSubmit : handleExpandClick}
        color={roadmapName ? "success" : "primary"}
      >
        Create
      </Button>
    </Box>
  );
};
