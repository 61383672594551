import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import ActionAlerts from "../../components/Alert/ActionAlerts";
import { apiLink } from "../../default";

const AddAdmins = () => {
  const [emails, setEmails] = useState<string[]>([]);
  const [newEmail, setNewEmail] = useState("");
  const [user, error] = useAuthState(auth);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleAddEmail = () => {
    setEmails([...emails, newEmail]);
    setNewEmail("");
  };

  const handleDeleteEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const handleSendRequest = async (perms: boolean) => {
    // Send request with the emails
    let token = await user?.getIdToken();

    const postData = {
      add_perms: perms,
      emails: emails,
    };
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(`${apiLink}/user/update_perms`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      setNotify({ isOpen: true, message: "Admins added", type: "success" });
    } else {
      setNotify({
        isOpen: true,
        message: "Unable to add all admins. Try refreshing the page and try again",
        type: "error",
      });
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10rem",
      }}
    >
      <ActionAlerts notify={notify} setNotify={setNotify} />
      <Box style={{ display: "flex", alignItems: "center" }}>
        <TextField label="Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
        <Button style={{ marginLeft: "2rem" }} variant="contained" onClick={handleAddEmail}>
          Add Email
        </Button>
      </Box>
      {emails.length > 0 && (
        <Box
          style={{
            margin: "2rem",
            padding: "1rem",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          {emails.map((email) => (
            <div key={email} style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>{email}</div>
              <Close style={{ marginLeft: "0.5rem", cursor: "pointer" }} onClick={() => handleDeleteEmail(email)} />
            </div>
          ))}
        </Box>
      )}
      <Box style={{}}>
        <Button style={{ margin: "2rem" }} variant="contained" onClick={() => handleSendRequest(true)}>
          Add Admins
        </Button>
        <Button style={{ margin: "2rem" }} variant="contained" onClick={() => handleSendRequest(false)}>
          Remove Admin
        </Button>
      </Box>
    </Box>
  );
};

export default AddAdmins;
