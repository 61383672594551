import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, Button, Container, InputAdornment } from "@mui/material";
import { Toolbar } from "@mui/material";
import { Footer } from "../../components/Footer/Footer";
import HeroHomepage from "./HeroHomepage";
import TopRatedRoadMaps from "./TopRatedRoadMaps";
import RoadMapsTopics from "./RoadMapsTopics";
import MyLearnings from "./MyLearnings";
import RoadMapsByAuthors from "./RoadMapsByAuthors";
import { Tab } from "@mui/base/Tab";
import { TabsList } from "@mui/base/TabsList";
import { TabPanel } from "@mui/base/TabPanel";
import { Tabs } from "@mui/base/Tabs";
import "./HomePage.css";
import TextField from "@mui/material/TextField";
import { apiLink, apiLink_classroom } from "../../default";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Roadmap } from "../../types/roadmap";
import { RecentRoadmaps } from "../../components/Roadmaps/RecentRoadmaps/RecentRoadmaps";
import SearchIcon from "@mui/icons-material/Search";
import ActionAlerts from "../../components/Alert/ActionAlerts";
import RoadMapsTags from "./RoadMapsTags";
import "./HomePage.css";
import MyAssignments from "./MyAssignments";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const HomePage = () => {
  const [user, error] = useAuthState(auth);

  const [topRatedData, setTopRatedData] = useState<Roadmap[] | undefined>(undefined);
  const [myAssignments, setMyAssignment] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabLabels] = useState([
    "Top Rated",
    "By Tags",
    "My Learnings",
    "By Authors",
    "By Topics",
    "Explore All",
    "My Assignments",
  ]);
  const [inputText, setInputText] = useState("");
  const [uidNameMap, setUidNameMap] = useState({ uid: String, name: String });
  // const uidNameMap: { uid: string; name: string }[] = JSON.parse(localStorage.getItem("uidNameMap") || "{uid: '', name: ''}");

  async function fetchUserCollection() {
    let uidNameMap: { uid: string; name: string }[] = [];
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {
      const uid = doc.data().uid;
      const name = doc.data().name;
      uidNameMap.push({ uid: uid, name: name });
    });
    await localStorage.setItem("uidNameMap", JSON.stringify(uidNameMap));
    setUidNameMap(JSON.parse(localStorage.getItem("uidNameMap") || ""));
  }

  async function fetchTopRatedData() {
    try {
      let token = await user?.getIdToken();
      const response = await fetch(`${apiLink}/roadmap/all/?sortByRank=true`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      const result = json.items;
      console.log(result);
      setTopRatedData(result);
      // setAurthorData();
      // console.log(aurthorData);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchMyAssignment() {
    var requestOptions = {
      method: "GET",
      redirect: "follow" as RequestRedirect,
    };
    const currentuser = auth.currentUser;
    const response = await fetch(`${apiLink_classroom}/user/${currentuser?.email}/`, requestOptions);
    const result = await response.json();
    console.log("result", result);
    if (result.is_teacher) {
      console.log("teacher_id", result.teacher_id);
      localStorage.setItem("teacher_id", result.data.teacher_id);
    } else {
      localStorage.setItem("student_id", result.data.student_id);
      localStorage.setItem("student_name", result.data.name);
    }
    setMyAssignment(result);
  }

  useEffect(() => {
    fetchUserCollection();
    fetchTopRatedData();
    fetchMyAssignment();
  }, []);

  let inputHandler = (e) => {
    //convert input text to lower case
    let lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const isBigScreen = useMediaQuery("(min-width: 600px)");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTabIndex(newValue);
  };

  return (
    <Box>
      <Toolbar />
      <HeroHomepage />
      <Box
        style={{
          width: "100%",
          paddingLeft: isBigScreen ? "10%" : "5%",
          paddingRight: isBigScreen ? "10%" : "5%",
          paddingBottom: "5%",
          paddingTop: "0%",
        }}
      >
        <Tabs defaultValue={0} className="tab-cont" onChange={handleChange} aria-label="basic tabs example">
          <TabsList className="tab-list">
            <Tab className="tab" value={0} {...a11yProps(0)}>
              {tabLabels[0]}
            </Tab>
            <Tab className="tab" value={1} {...a11yProps(0)}>
              {tabLabels[1]}
            </Tab>
            <Tab className="tab" value={2} {...a11yProps(0)}>
              {tabLabels[2]}
            </Tab>
            <Tab className="tab" value={3} {...a11yProps(0)}>
              {tabLabels[3]}
            </Tab>
            <Tab className="tab" value={4} {...a11yProps(0)}>
              {tabLabels[4]}
            </Tab>
            <Tab className="tab" value={5} {...a11yProps(0)}>
              {tabLabels[5]}
            </Tab>
            <Tab className="tab" value={6} {...a11yProps(0)}>
              {tabLabels[6]}
            </Tab>
          </TabsList>
          <Box style={{ padding: "15px" }}>
            <TextField
              id="search"
              type="search"
              label={"Search " + tabLabels[tabIndex]}
              onChange={inputHandler}
              sx={{ width: 300 }}
              style={{ borderRadius: "50%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TabPanel value={0}>
            <TopRatedRoadMaps input={inputText} topRatedData={topRatedData} />
          </TabPanel>
          <TabPanel value={1}>
            <RoadMapsTags input={inputText} />
          </TabPanel>
          <TabPanel value={2}>
            <MyLearnings input={inputText} />
          </TabPanel>
          <TabPanel value={3}>
            <RoadMapsByAuthors input={inputText} authorData={uidNameMap} />
          </TabPanel>
          <TabPanel value={4}>
            <RoadMapsTopics input={inputText} />
          </TabPanel>
          <TabPanel value={5}>
            <RecentRoadmaps />
          </TabPanel>
          <TabPanel value={6}>
            <MyAssignments props={myAssignments} />
          </TabPanel>
        </Tabs>
      </Box>
      <Footer />
    </Box>
  );
};

export default HomePage;
