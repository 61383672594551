import React, { useState, useEffect } from "react";
import { Box, Typography, Button, TextField, List, FormGroup, FormControlLabel } from "@mui/material";
import PermLists from "../../Lists/PermLists";
import { Close } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { apiLink } from "../../../default";
import ActionAlerts from "../../Alert/ActionAlerts";
import { auth } from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const PermissionDashboard = (props) => {
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = React.useState([-1]);
  const [checkedSelectAll, setCheckedSelectAll] = useState(false);
  const [user, error] = useAuthState(auth);

  const [checkedView, setCheckedView] = React.useState([-1]);

  const [editAccessUsers, setEditAccessUsers] = useState<string[]>([]);
  const [editAccessUsersUID, setEditAccessUsersUID] = useState<string[]>([]);
  const [viewAccessUsers, setViewAccessUsers] = useState<string[]>([]);
  const [viewAccessUsersUID, setViewEditAccessUsersUID] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedAdmins, setSelectedAdmins] = useState(true);
  const [newEmail, setNewEmail] = useState("");

  const mapUIDToName = (resData) => {
    const resArr: string[] = [];
    const userIDs = resData;
    setEditAccessUsersUID(userIDs);
    const uidNameMap: { uid: string; name: string }[] = JSON.parse(localStorage.getItem("uidNameMap") || "");
    for (let i = 0; i < userIDs.length; i++) {
      for (let j = 0; j < uidNameMap.length; j++) {
        if (userIDs[i] === uidNameMap[j].uid) {
          resArr.push(uidNameMap[j].name);
        }
      }
    }

    setEditAccessUsers(resArr);
  };

  const mapUIDToNameView = (resData) => {
    const resArr: string[] = [];
    const userIDs = resData;
    setViewEditAccessUsersUID(userIDs);
    const uidNameMap: { uid: string; name: string }[] = JSON.parse(localStorage.getItem("uidNameMap") || "");
    for (let i = 0; i < userIDs.length; i++) {
      for (let j = 0; j < uidNameMap.length; j++) {
        if (userIDs[i] === uidNameMap[j].uid) {
          resArr.push(uidNameMap[j].name);
        }
      }
    }

    setViewAccessUsers(resArr);
  };

  const fetchData = async () => {
    let token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    const responseEdit = await axios.get(`${apiLink}/roadmap/user_access/`, {
      headers,
      params: {
        roadmapID: props.roadmap_id,
        isAdmin: true,
      },
    });
    const responseView = await axios.get(`${apiLink}/roadmap/user_access/`, {
      headers,
      params: {
        roadmapID: props.roadmap_id,
        isAdmin: false,
      },
    });

    mapUIDToName(responseEdit.data.data.userIDs);
    mapUIDToNameView(responseView.data.data.userIDs);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetchData();

    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  const addUserPerms = async (editAccess: boolean) => {
    console.log(editAccess);

    let token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    const requestData = {
      roadmapID: props.roadmap_id,
      isAdmin: editAccess,
      userIDs: emails,
    };
    const response = await axios.post(`${apiLink}/roadmap/user_access`, requestData, {
      headers,
    });
    try {
      if (response.status === 200) {
        await fetchData();
        if (!editAccess) {
          setNotify({
            isOpen: true,
            message: "Permissions added",
            type: "success",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Permissions added",
            type: "success",
          });
        }
      } else {
        throw new Error("An error has occurred");
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        message: `Unable to add Permissions: ${error}`,
        type: "error",
      });
    }
  };

  const removeUserPerms = async (editAccess: boolean) => {
    let token = await user?.getIdToken();
    const removeUID: string[] = [];
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    const requestData = {
      roadmapID: props.roadmap_id,
      isAdmin: editAccess,
      userIDs: removeUID,
    };
    if (!editAccess) {
      for (let index = 0; index < checkedView.length; index++) {
        const uidIndex = checkedView[index];
        if (uidIndex !== -1) {
          removeUID.push(viewAccessUsersUID[uidIndex]);
        }
      }
    } else {
      for (let index = 0; index < checked.length; index++) {
        const uidIndex = checked[index];
        if (uidIndex !== -1) {
          removeUID.push(editAccessUsersUID[uidIndex]);
        }
      }
    }

    const response = await axios.delete(`${apiLink}/roadmap/user_access`, {
      headers,
      data: requestData,
    });
    try {
      if (response.status === 200) {
        fetchData();
        setNotify({
          isOpen: true,
          message: "Edit Permissions Removed",
          type: "success",
        });
      } else {
        throw new Error("An error has occurred");
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        message: `Unable to Remove Permissions: ${error}`,
        type: "error",
      });
    }
  };

  const handleSelectAll = () => {
    if (selectedAdmins) {
      setCheckedSelectAll(!checkedSelectAll);
      if (!checkedSelectAll) {
        const newChecked = editAccessUsersUID.map((_, index) => index);
        setChecked(newChecked);
      } else {
        setChecked([-1]);
      }
    } else {
      setCheckedSelectAll(!checkedSelectAll);
      if (!checkedSelectAll) {
        const newChecked = viewAccessUsersUID.map((_, index) => index);
        setCheckedView(newChecked);
      } else {
        setCheckedView([-1]);
      }
    }
  };

  const handleTogleEdit = () => {
    if (!selectedAdmins) {
      setSelectedAdmins(!selectedAdmins);
    }
  };

  const handleTogleView = () => {
    if (selectedAdmins) {
      setSelectedAdmins(!selectedAdmins);
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddEmail = () => {
    if (!validateEmail(newEmail)) {
      setNotify({
        isOpen: true,
        message: "Invalid email address",
        type: "error",
      });
      return;
    }
    console.log(JSON.parse(localStorage.getItem("uidNameMap") || ""));
    setEmails([...emails, newEmail]);
    setNewEmail("");
  };

  const handleDeleteEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleView = (value: number) => () => {
    const currentIndex = checkedView.indexOf(value);
    const newChecked = [...checkedView];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedView(newChecked);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90vw",
        height: "90vh",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 1,
      }}
    >
      <Typography
        sx={{ marginTop: "8px", marginBottom: "8px", textAlign: "center" }}
        id="modal-modal-title"
        variant="h4"
        component="h2"
      >
        Permission dashboard
      </Typography>
      <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
        <Box
          sx={{
            width: "50%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{ marginLeft: "5px", marginRight: "5px" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Add Users
            </Typography>

            <Button
              onClick={() => addUserPerms(false)}
              style={{ marginLeft: "5px", marginRight: "5px" }}
              variant="outlined"
            >
              Give View Access
            </Button>
            <Button
              onClick={() => addUserPerms(true)}
              sx={{ marginLeft: "5px", marginRight: "5px" }}
              variant="outlined"
              component="button"
            >
              Give Edit Access
            </Button>
          </Box>
          <Box sx={{ marginTop: "2rem", width: "90%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
              <TextField label="Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
              <Button style={{ marginLeft: "10px", height: "40px" }} variant="outlined" onClick={handleAddEmail}>
                Add to User Perm List
              </Button>
            </Box>
          </Box>

          {emails.map(function (data) {
            return (
              <div
                key={data}
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "2px solid",
                  marginTop: "1rem",
                }}
              >
                <div style={{ padding: ".6rem" }}>{data}</div>
                <Close style={{ marginLeft: "0.5rem", cursor: "pointer" }} onClick={() => handleDeleteEmail(data)} />
              </div>
            );
          })}
        </Box>
        <Box sx={{ backgroundColor: "black", height: "83vh", width: "2px" }}></Box>
        <Box
          sx={{
            width: "50%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{ marginLeft: "5px", marginRight: "5px" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Remove Users
            </Typography>

            <Button
              onClick={() => removeUserPerms(false)}
              style={{ marginLeft: "5px", marginRight: "5px" }}
              variant="outlined"
            >
              Remove View Access
            </Button>
            <Button
              onClick={() => removeUserPerms(true)}
              sx={{ marginLeft: "5px", marginRight: "5px" }}
              variant="outlined"
            >
              Remove Edit Access
            </Button>
          </Box>
          <Box sx={{ marginTop: "2rem", width: "90%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
              <Button sx={{ marginLeft: "5px", marginRight: "5px" }} onClick={handleTogleEdit} variant="outlined">
                View Users with Edit Access
              </Button>
              <Button style={{ marginLeft: "5px", marginRight: "5px" }} onClick={handleTogleView} variant="outlined">
                View Users with View Access
              </Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Select All"
                  checked={checkedSelectAll}
                  onChange={handleSelectAll}
                  sx={{ marginTop: "1.5rem" }}
                />
              </FormGroup>

              <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                {selectedAdmins ? (
                  <PermLists
                    editAccessUsers={editAccessUsers}
                    handleToggle={handleToggle}
                    checked={checked}
                    isAdmin={true}
                  ></PermLists>
                ) : (
                  <PermLists
                    editAccessUsers={viewAccessUsers}
                    handleToggle={handleToggleView}
                    checked={checkedView}
                    isAdmin={false}
                  ></PermLists>
                )}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
      <ActionAlerts notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default PermissionDashboard;
