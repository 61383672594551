import {S3Client, PutObjectCommand, DeleteObjectCommand} from "@aws-sdk/client-s3";


const SECRET_ACCESS_KEY = "eFIHcmaGZP3Ec57dThhJGO0woWfeKVP6qTuku7SM"
const ACCESS_KEY_ID = "tmnzIczl3hqCroMPvNd4"
const MINIOENDPOINT = "https://minio.xlabub.com/"


let s3 = new S3Client({
    region: 'us-east-2',
    credentials: {
        secretAccessKey: SECRET_ACCESS_KEY,
        accessKeyId: ACCESS_KEY_ID
    },
    // for minio
    endpoint: MINIOENDPOINT,
    forcePathStyle: true
});


const uploadToS3 = async (file, name) => {
    const params = {
        "Bucket": "alasia",
        "Key": `UserUploadedPDFs/${name}.pdf`,
        "ContentType": "application/pdf",
        "Body": file
    };
    const command = new PutObjectCommand(params);
    const response = await s3.send(command);
    return response;
}

const deleteFromS3 = async (stable_url) => {
    const params = {
        Bucket: "alasia",
        Key: stable_url
    };
    const command = new DeleteObjectCommand(params);
    const response = await s3.send(command);
    return response;
}


export {
    uploadToS3,
    deleteFromS3
}
