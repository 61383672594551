import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  useMediaQuery,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import React, { ChangeEvent, FormEvent, useState } from "react";
import Collapse from "@mui/material/Collapse";
import { auth } from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AccountCircle, PlaylistAdd } from "@mui/icons-material";
import "./AddPaper.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiLink } from "../../../default";
import { getAutoComplete, getPaperFromOA } from "../../Roadmaps/BoardArea/OAManager";
import { wordFilter } from "../../../service/wordFilter";
import { uploadToS3 } from "../../../aws";

export interface AddPaperProps {
  boardId: string;
  show: boolean;
  onClose: () => void;
}

export const AddPaper = ({ boardId, level, refreshOnSuccess, setNotify }) => {
  const [user, error] = useAuthState(auth);
  const [confirmModal, setConfirmModal] = useState(false);
  const [difficulty, setDifficulty] = useState(1);

  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  // const isAdmin = true;
  const isAdmin = localStorage.getItem("isAdmin");
  const [expanded, setExpanded] = React.useState(false);
  const [expandPDF, setExpandPDF] = useState<boolean>(false);
  const [expandSearchOpen, setExpandSearchOpen] = useState<boolean>(false);
  const [backDropText, setBackDropText] = useState<string>("DO NOT REFRESH THE PAGE");
  const [paperName, setPaperName] = React.useState("");
  const [paperLink, setPaperLink] = React.useState("");
  const [unpaywallPaperName, setUnpaywallPaperName] = useState<string>("");
  const [unpaywallPaperLink, setUnpaywallPaperLink] = useState<string>("");
  const [autoSuggestPaperName, setAutoSuggestPaperName] = React.useState<any[]>([]);
  const isBigScreen = useMediaQuery("(min-width: 600px)");

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setExpandSearchOpen(false);
    setExpandPDF(false);
  };

  const handleSearchClick = () => {
    setExpandSearchOpen(!expandSearchOpen);
    setExpanded(false);
    setExpandPDF(false);
  };

  const searchOpenSource = async (fieldText) => {
    try {
      const response = await fetch(
        `https://api.unpaywall.org/v2/search?query=${fieldText}/&email=northstar.team23@gmail.com&is_oa=${true}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setAutoSuggestPaperName(data.results);
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Error occured while searching for paper",
        type: "error",
      });
    }
  };

  const handleSelect = async (item) => {
    if (item.url !== "" && item.url !== null) {
      handleSubmitOnSelect(item?.display_name, item?.external_id);
    } else {
      let index = item.id.lastIndexOf("/");
      let id = item.id.substring(index + 1);
      let response = await getPaperFromOA({ paperID: id });
      console.log(response);
      // console.log("Paper is", JSON.stringify(response, undefined, 2))

      // TODO: - Find ways to make this typecheck robust
      // The URL can reside in other places too and sometimes the URL we get
      // may not even have PDF but be paywalled.
      if (!response.display_name || !response.host_venue?.url) {
        if (response.primary_location && response.primary_location.landing_page_url) {
          setPaperName(response.display_name);
          setPaperLink(response.primary_location.landing_page_url);
          handleSubmit();
        } else {
          // Alert.alert("Unable to add", "Sorry the paper you have selected cannot be added.")
        }
      } else {
        setPaperName(response.display_name);
        setPaperLink(response.primary_location.landing_page_url);
        handleSubmit();
      }
    }
  };

  const handleSubmitOnSelect = async (name, link) => {
    setLoading(true);

    const paperDetails = {
      name: name,
      publish: new Date(),
      roadmaps: [
        {
          rm: boardId,
          level: level,
          difficulty: difficulty,
        },
      ],
      link: link,
    };

    let token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.post(`${apiLink}/paper/`, paperDetails, {
      headers,
    });
    try {
      if (response.status === 200) {
        setLoading(false);
        window.location.reload();
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: "Error occured while uploading PDF",
          type: "error",
        });
        throw new Error("An error has occurred");
      }
    } catch (error) {
      console.log("An error has occurred");
    }
  };

  const handleUnPaywallPaper = async () => {
    setBackDropText("DO NOT REFRESH THE PAGE: LARGE PDFS MAY TAKE A LONGER TIME TO UPLOAD");
    setLoading(true);
    let token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };

    let resLink = "";
    try {
      const getPaperLink = await axios.post(
        `${apiLink}/paper/upload_pdf/?link=${unpaywallPaperLink}&pdf_name=${unpaywallPaperName}`,
        {},
        {
          headers,
        }
      );

      resLink = getPaperLink.data.link;
    } catch (error: any) {
      setLoading(false);

      setNotify({
        isOpen: true,
        message: error.response.data.detail,
        type: "error",
      });
    }

    if (resLink !== "") {
      try {
        const resName = unpaywallPaperName;
        const paperDetails = {
          name: resName,
          publish: new Date(),
          roadmaps: [
            {
              rm: boardId,
              level: level,
              difficulty: difficulty,
            },
          ],
          link: resLink,
        };

        await axios.post(`${apiLink}/paper/`, paperDetails, {
          headers,
        });
        setLoading(false);
        window.location.reload();
      } catch (error: any) {
        setNotify({
          isOpen: true,
          message: error.response.data.detail,
          type: "error",
        });
      }
    }
  };

  const handleOpenConfirmModal = (name: string, link: string) => {
    setConfirmModal(!confirmModal);
    setUnpaywallPaperName(name);
    setUnpaywallPaperLink(link);
  };

  function handlePDFSelect(e) {
    if (e.target.files[0]) {
      setExpandSearchOpen(false);
      setExpanded(false);
      setExpandPDF(true);
      const sm = e.target.files[0].name.slice(0, e.target.files[0].name.length - 4);

      setFile(e.target.files[0]);
      setPaperName(sm);
    }
  }

  const closePDFCollapse = () => {
    setExpandPDF(false);
    setFile(undefined);
    setPaperName("");
  };

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  const uploadPDf = async () => {
    // const paperURL = await uploadPDF(file, paperName);
    const paperURL = await uploadToS3(file, paperName);

    if (paperURL.$metadata.httpStatusCode === 200) {
      await handleSubmitOnSelect(paperName, `https://minio.xlabub.com/alasia/UserUploadedPDFs/${paperName}.pdf`);
    } else {
      setNotify({
        isOpen: true,
        message: "A Error occured while uploading the PDF",
        type: "error",
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const paperDetails = {
      name: paperName,
      publish: new Date(),
      roadmaps: [
        {
          rm: boardId,
          level: level,
          difficulty: difficulty,
        },
      ],
      link: paperLink,
    };
    let token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(`${apiLink}/paper/`, paperDetails, {
        headers,
      });
      setLoading(false);
      window.location.reload();
    } catch (error: any) {
      setNotify({
        isOpen: true,
        message: error.response.data.detail,
        type: "error",
      });
    }
  };

  return (
    <Box
      style={{
        marginTop: "40px",
        marginLeft: isBigScreen ? "100px" : "0px",
        marginRight: isBigScreen ? "100px" : "10px",
      }}
    >
      <Dialog
        open={confirmModal}
        onClose={closeConfirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to add this paper?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you try to add the paper and it returns a error; add the paper manually by downloading the pdf paper from
            unpaywall and upload it using the upload pdf button. Please make sure your paper is open access if you
            decide to use the upload feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmModal}>Cancel</Button>
          <Button onClick={handleUnPaywallPaper} autoFocus>
            Add Paper
          </Button>
        </DialogActions>
      </Dialog>

      <Collapse in={expandSearchOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">Click Enter to Search</InputLabel>
            <Input
              sx={{ width: "45rem" }}
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter") {
                  // Call your function here
                  const searchText = (event.target as HTMLInputElement).value;
                  await searchOpenSource(searchText);
                }
              }}
            />
          </FormControl>

          {autoSuggestPaperName.map((item, index) => (
            <List style={{ width: "100%" }} key={index}>
              <ListItem style={{ backgroundColor: "white" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "80%" }}>
                  <ListItemText>{item?.response.title}</ListItemText>
                  <h5 style={{ fontSize: ".7rem" }}>{item?.response.journal_name}</h5>
                  <Box sx={{ marginTop: "2px" }}>
                    {item?.response.best_oa_location.url_for_landing_page ? (
                      <Link
                        sx={{ marginRight: ".3rem" }}
                        target="_blank"
                        rel="noopener"
                        href={item?.response.best_oa_location.url_for_landing_page}
                      >
                        [HTML]
                      </Link>
                    ) : (
                      <></>
                    )}
                    {item?.response.best_oa_location.url_for_pdf ? (
                      <Link
                        sx={{ marginLeft: ".3rem" }}
                        target="_blank"
                        rel="noopener"
                        href={item?.response.best_oa_location.url_for_pdf}
                      >
                        [PDF]
                      </Link>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", width: "20%" }}>
                  {item?.response.best_oa_location.url_for_pdf ? (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#00FF00", color: "white" }}
                      onClick={() =>
                        handleOpenConfirmModal(item?.response.title, item?.response.oa_locations[0].url_for_pdf)
                      }
                    >
                      Add Paper
                    </Button>
                  ) : (
                    <Button variant="contained" style={{ backgroundColor: "#FF0000", color: "white" }}>
                      Not Supported
                    </Button>
                  )}
                </Box>
              </ListItem>
            </List>
          ))}
        </Box>
      </Collapse>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "2px dashed #536680",
            borderRadius: "10px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <TextField
            required
            label="Paper Name"
            variant="filled"
            style={{ width: "90%", marginBottom: "15px" }}
            defaultValue={paperName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPaperName(event.target.value)}
          />
          {/* <Button variant="contained" onClick={searchOpenSource}>
            Search OpenAlex
          </Button> */}

          <TextField
            required
            label="Paper Link"
            variant="filled"
            style={{ width: "90%" }}
            defaultValue={paperLink}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPaperLink(event.target.value)}
          />
        </Box>
      </Collapse>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.tooltip + 1,
          flexDirection: "column",
          alignItems: "center",
        }}
        open={loading}
      >
        <h4>{backDropText}</h4>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Collapse in={expandPDF} timeout="auto" unmountOnExit>
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "2px dashed #536680",
            borderRadius: "10px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <ExpandMoreIcon
            style={{
              alignItems: "flex-end",
              marginLeft: "90%",
              marginBottom: "20px",
              cursor: "pointer",
            }}
            onClick={closePDFCollapse}
          ></ExpandMoreIcon>

          <TextField
            required
            label="Paper Name"
            variant="filled"
            style={{ width: "90%", marginBottom: "15px" }}
            defaultValue={paperName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPaperName(event.target.value)}
          />

          <Button
            variant="contained"
            component="span"
            style={{
              margin: "8px",
              backgroundColor: "#536680",
              borderRadius: "20px",
              maxWidth: "200px",
              maxHeight: "40px",
            }}
            onClick={uploadPDf}
          >
            Add Uploaded Paper
          </Button>
        </Box>
      </Collapse>

      <Box
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          startIcon={<PlaylistAdd />}
          style={{ borderRadius: "20px", backgroundColor: "#3d5a80", marginRight: "10px" }}
          onClick={paperName ? handleSubmit : handleExpandClick}
        >
          Add Paper
        </Button>
        <Button
          variant="contained"
          startIcon={<PlaylistAdd />}
          style={{ borderRadius: "20px", backgroundColor: "#3d5a80", marginRight: "10px" }}
          onClick={handleSearchClick}
        >
          Search UnPaywall
        </Button>
        {isAdmin === "true" ? (
          <label htmlFor="upload-pdf">
            <input
              style={{ display: "none" }}
              id="upload-pdf"
              name="upload-pdf"
              type="file"
              onChange={(e) => handlePDFSelect(e)}
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              style={{
                backgroundColor: "#536680",
                borderRadius: "20px",
                maxWidth: "150px",
                maxHeight: "40px",
              }}
            >
              Upload PDF
            </Button>
          </label>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
