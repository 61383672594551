import { useState, useEffect } from "react";
import { Box, Grid, Grow, useMediaQuery } from "@mui/material";
import { Roadmap } from "../../types/roadmap";
import Spinners from "../../components/Spinners/Spinners";
import CardArea from "../../components/Card/CardArea";
import { useNavigate } from "react-router-dom";
import { apiLink } from "../../default";
import "./TopRatedRoadMaps.css";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const MyLearnings = (props) => {
  const [recentBoards, setRecentBoards] = useState<Roadmap[]>([]);
  const history = useNavigate();
  const isBigScreen = useMediaQuery("(min-width: 600px)");
  const [showSpinner, setShowSpinner] = useState(true);
  const [user, error] = useAuthState(auth);

  useEffect(() => {
    async function fetchData() {
      try {
        let token = await user?.getIdToken();
        const response = await fetch(`${apiLink}/user/learning_list`, {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        });
        const result = await response.json();
        setRecentBoards(result.data.learning_list);
        setShowSpinner(false);
      } catch (error) {
        setShowSpinner(false);

        console.log(error);
      }
    }
    fetchData();
  }, []);

  const openRoadmap = (board) => {
    history("/boards/" + `${board.uid}`, board);
  };

  const filteredData = recentBoards?.filter((el) => {
    //if no input the return the original
    if (props.input === "") {
      return el;
    }
    //return the item which contains the user input
    else {
      return el.name.toLowerCase().includes(props.input);
    }
  });

  return (
    <Box style={{ paddingBottom: "5%", paddingTop: "0%" }}>
      <Grid container spacing={0} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Grid item={true} xs={isBigScreen ? 12 : 12}>
          <Grow style={{ paddingTop: "20px", minHeight: "300px" }} in={true} timeout={1000}>
            <div>
              {showSpinner ? <Spinners /> : <></>}

              {recentBoards.length > 0 ? (
                <div className="outer-card-cont">
                  {filteredData?.map((recentBoard) => (
                    <CardArea roadmap={recentBoard} inLearningList={false} key={recentBoard.uid} />
                  ))}
                </div>
              ) : (
                <></>
              )}

              {recentBoards.length == 0 && !showSpinner ? <h2>No Roadmaps associated with user</h2> : <></>}
            </div>
          </Grow>
        </Grid>
      </Grid>
    </Box>
  );
};
export default MyLearnings;
