import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function ActionAlerts(props) {
  const { notify, setNotify } = props;

  const handleClose = (event, reason) => {
    setNotify({ ...notify, isOpen: false });
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={notify.isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={notify.severity || "info"} // Ensure severity has a valid value or default to 'info'
          sx={{ width: "100%" }}
        >
          {notify.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
