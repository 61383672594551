import {
  Typography,
  Box,
  useMediaQuery,
  Button,
  Fade,
  TextField,
  Tooltip,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import BAPdfCard from "./BAPdfCard";
import React, { useState, useEffect } from "react";
import { Roadmap } from "../../../types/roadmap";
import { AddPaper } from "../../Tasks/AddPaper/AddPaper";
import "./BoardArea.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Toolbar from "@mui/material/Toolbar";
import { Edit } from "@mui/icons-material";
import axios from "axios";
import BoardAreaHeader from "./BoardAreaHeader";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Card, CardContent, Rating, CardMedia, CardActions, CardActionArea } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Spinners from "../../Spinners/Spinners";
import { uploadPDF, uploadThumbnail, auth, deletePDF, deletePDFUnpaywall } from "../../../firebase";
import { Footer } from "../../Footer/Footer";
import { apiLink } from "../../../default";
import { deleteFromS3 } from "../../../aws";
import ActionAlerts from "../../Alert/ActionAlerts";
import { useAuthState } from "react-firebase-hooks/auth";
import { wordFilter } from "../../../service/wordFilter";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Card {
  id: string;
  title: string;
  link: string;
}
type Severity = "error" | "success" | "info" | "warning" | undefined;

export const BoardArea = (props) => {
  const [user, error] = useAuthState(auth);

  let { id } = useParams();
  const [boardId, setBoardId] = useState(props.boardId);
  const [board, setBoard] = useState<Roadmap | undefined>(undefined);
  const [papers, setPapers] = useState([]);

  const [columns, setColumns] = useState<any>([]);
  const [paperLoading, setPaperLoading] = useState(true);
  const [backDropText, setBackDropText] = useState<string>("DO NOT REFRESH THE PAGE");

  const [hasEditAccess, setHasEditAccess] = useState(false);
  const [hasViewAccess, setHasViewAccess] = useState(false);
  // const [starRating, setStarRating] = React.useState<any | undefined>(0);
  const [value, setValue] = React.useState(0);
  const [boardLevels, setBoardLevels] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const isBigScreen = useMediaQuery("(min-width:600px)");
  const [paperName, setPaperName] = React.useState("");
  const [paperLink, setPaperLink] = React.useState("");
  const [cardID, setCardID] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [expandPDFEdit, setExpandPDFEdit] = useState<boolean>(false);
  const [fileEdit, setFileEdit] = useState<File>();
  const [severity, setSeverity] = useState<Severity>("success");

  const handleOpen = (paper_link: string, paper_name: string, card_id: string) => {
    setPaperLink(paper_link);
    setPaperName(paper_name);
    setCardID(card_id);
    setOpen(true);
  };
  const handleClose = () => {
    setExpandPDFEdit(false);
    setFileEdit(undefined);
    setPaperName("");
    setOpen(false);
  };
  // const isAdmin = true;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const checkIfFirebaseURLQuery = (link) => {
    if (link.slice(0, 23) === "https://firebasestorage") {
      return {
        isFurl: true,
        isUnpaywallUrl: false,
      };
    } else if (link.slice(0, 30) === "https://storage.googleapis.com") {
      return {
        isFurl: true,
        isUnpaywallUrl: true,
      };
    } else {
      return {
        isFurl: false,
        isUnpaywallUrl: false,
      };
    }
  };

  const fetchPerms = async (tmpBoard) => {
    let token = await user?.getIdToken();
    console.log(tmpBoard.public);
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const responseEdit = await axios.get(`${apiLink}/roadmap/user_access/`, {
      headers,
      params: {
        roadmapID: tmpBoard.uid,
        isAdmin: true,
      },
    });
    const responseView = await axios.get(`${apiLink}/roadmap/user_access/`, {
      headers,
      params: {
        roadmapID: tmpBoard.uid,
        isAdmin: false,
      },
    });

    for (let index = 0; index < responseEdit.data.data.userIDs.length; index++) {
      const element = responseEdit.data.data.userIDs[index];
      if (element === localStorage.getItem("userUID")) {
        setHasEditAccess(true);
      }
    }

    for (let index = 0; index < responseView.data.data.userIDs.length; index++) {
      const element = responseView.data.data.userIDs[index];
      if (element === localStorage.getItem("userUID")) {
        setHasViewAccess(true);
      }
    }

    if (localStorage.getItem("userUID") === tmpBoard.author) {
      setHasEditAccess(true);
      setHasViewAccess(true);
    }

    if (localStorage.getItem("student_id")) {
      setHasViewAccess(true);
    }

    setPaperLoading(false);
  };

  const getTasksByStatus = (paperData) => {
    const col1: Card[] = [];
    const col2: Card[] = [];
    const col3: Card[] = [];

    for (let index = 0; index < paperData.length; index++) {
      const paper = paperData[index];

      if (paper.level === 1) {
        const tmpCard = {
          id: paper.uid,
          title: paper.name,
          link: paper.link,
        };
        col1.push(tmpCard);
      } else if (paper.level === 2) {
        const tmpCard = {
          id: paper.uid,
          title: paper.name,
          link: paper.link,
        };
        col2.push(tmpCard);
      } else {
        const tmpCard = {
          id: paper.uid,
          title: paper.name,
          link: paper.link,
        };
        col3.push(tmpCard);
      }
    }

    const cols = [
      {
        id: 1,
        title: "Level 1",
        cards: col1,
      },
      {
        id: 2,
        title: "Level 2",
        cards: col2,
      },
      {
        id: 3,
        title: "Level 3",
        cards: col3,
      },
    ];

    setColumns(cols);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index - 1}`,
      "aria-controls": `simple-tabpanel-${index - 1}`,
    };
  }

  const getPapersdata = async () => {
    try {
      const token = await user?.getIdToken();
      const response = await fetch(`${apiLink}/roadmap/papers/${boardId}`, {
        method: "GET",

        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      console.log(json);
      if (papers.length === 0) {
        setPapers(json);
        if (json.length > 0) {
          getTasksByStatus(json);
        }

        return json.roadmap_papers;
      }
    } catch (error) {}
  };

  const fetchBoard = async (boardId) => {
    try {
      const token = await user?.getIdToken();
      const response = await fetch(`${apiLink}/roadmap/info/${boardId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();

      setBoard(json);
      setBoardLevels(json.levels);
      if (!json.public) {
        await fetchPerms(json);
      } else {
        if (localStorage.getItem("userUID") === json.author) {
          setHasEditAccess(true);
        }
        setHasViewAccess(true);
      }
      // setControlLabel(json.public);
      // checkRoadmapOwner(json);
      return json;
    } catch (error) {
      console.log(error);
    }
  };

  const updateLevel = async () => {
    const abortController = new AbortController();
    const token = await user?.getIdToken();

    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    const postData = { ...board, levels: boardLevels + 1 };

    try {
      const response = await axios.put(`${apiLink}/roadmap/?roadmap_id=${boardId}`, postData, {
        params: { roadmap_id: boardId },
        headers,
      });
      if (response.status === 200) {
        setNotify({ isOpen: true, message: "Level Updated", type: "success" });

        fetchBoard(boardId);
      } else {
        setNotify({
          isOpen: true,
          message: "Unable to update level",
          type: "error",
        });
        throw new Error("An error has occurred");
      }
    } catch (error) {
      setNotify({ isOpen: true, message: "Error occured", type: "error" });
    }
  };

  const renderTabs = () => {
    let tabs = Array();
    let levelNames = ["Beginner", "Intermediate", "Advanced"];
    for (let i = 0; i < boardLevels; i++) {
      tabs.push(<Tab key={i} label={levelNames[i]} {...a11yProps(i + 1)} />);
    }
    return tabs;
  };

  const deleteTask = async (event, paperId, paperLink, paperName) => {
    setLoading(true);
    event?.preventDefault();
    event?.stopPropagation();
    const token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    let pName = paperName;

    const checkURL = checkIfFirebaseURLQuery(paperLink);

    try {
      const response = await axios.delete(`${apiLink}/paper/?paper_id=${paperId}&roadmap_id=${boardId}`, {
        headers,
      });
      if (checkURL.isFurl) {
        if (response.status === 200) {
          try {
            if (checkURL.isUnpaywallUrl) {
              await deletePDFUnpaywall(paperLink);
            } else {
              await deletePDF(pName);
            }

            window.location.reload();
          } catch (error) {
            setLoading(false);
            setNotify({
              isOpen: true,
              message: "Error Occured while removing paper",
              type: "error",
            });
          }
        } else {
          setNotify({
            isOpen: true,
            message: "Unable to remove paper",
            type: "error",
          });
        }
      } else if (paperLink.includes("https://minio.xlabub.com/")) {
        const minioPrefix = "https://minio.xlabub.com/";
        const objectPath = paperLink.slice(minioPrefix.length);

        const res = await deleteFromS3(objectPath);
        console.log(res);
        // window.location.reload();
      } else {
        if (response.status === 200) {
          getPapersdata();
          window.location.reload();
        } else {
          setNotify({
            isOpen: true,
            message: "Unable to remove paper",
            type: "error",
          });
        }
      }
    } catch (error: any) {
      setNotify({
        isOpen: true,
        message: error.response.data.detail,
        type: "error",
      });
    }
  };

  const handlePaperChange = async (cardID, paperName, paperLink) => {
    const token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    if (fileEdit) {
      console.log("file");
      const paperURL = await uploadPDF(fileEdit, paperName);
      const payload = {
        name: wordFilter.clean(paperName),
        link: paperURL,
      };
      const response = await axios.put(`${apiLink}/paper/${cardID}`, payload, { headers });
      try {
        if (response.status === 200) {
          fetchBoard(boardId);
          setNotify({
            isOpen: true,
            message: "Roadmap updated successfully",
            type: "success",
          });
          console.log(` You have updated: ${JSON.stringify(response.data)}`);
          handleClose();
          window.location.reload();
        } else {
          setNotify({
            isOpen: true,
            message: "Unable to update roadmap",
            type: "error",
          });
          throw new Error("An error has occurred");
        }
      } catch (error) {
        setNotify({
          isOpen: true,
          message: "Error occured while updating roadmap",
          type: "error",
        });
        console.log("An error has occurred");
      }
    } else {
      const payload = {
        name: wordFilter.clean(paperName),
        link: paperLink,
      };

      try {
        const response = await axios.patch(`${apiLink}/paper/${cardID}`, payload, { headers });
        if (response.status === 200) {
          // fetchBoard(boardId);
          setNotify({
            isOpen: true,
            message: "Roadmap updated successfully",
            type: "success",
          });
          // console.log(` You have updated: ${JSON.stringify(response.data)}`);
          // handleClose();
          window.location.reload();
        } else {
          setNotify({
            isOpen: true,
            message: "Unable to update roadmap",
            type: "error",
          });
          throw new Error("An error has occurred");
        }
      } catch (error) {
        setNotify({
          isOpen: true,
          message: "Error occured while updating roadmap",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      await fetchBoard(props.boardId);
      await getPapersdata();
      setPaperLoading(false);
    }
    fetchData();

    return () => {
      setBoardLevels(0);
      setPapers([]);
      setColumns([]);
    };
  }, [props]);

  const isEmptyRecentBoards = (): boolean => {
    if (!board) {
      return true;
    }
    if (board && Object.keys(board).length == 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Toolbar />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.tooltip + 1,
          flexDirection: "column",
          alignItems: "center",
        }}
        open={loading}
      >
        <h4>{backDropText}</h4>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box style={{ width: "100%", minHeight: "83vh", paddingTop: "60px" }}>
        {/* <ActionAlerts /> */}
        {paperLoading ? <Spinners /> : <></>}
        {board ? (
          <Box
            style={{
              width: "100%",
              paddingLeft: isBigScreen ? "10%" : "5%",
              paddingRight: isBigScreen ? "10%" : "5%",
            }}
          >
            {hasViewAccess && !paperLoading ? (
              <BoardAreaHeader
                roadmapData={board}
                setBoardId={setBoardId}
                hasEditAccess={hasEditAccess}
                setNotify={setNotify}
                fetchBoard={fetchBoard}
              ></BoardAreaHeader>
            ) : (
              <></>
            )}

            {!hasEditAccess && !hasViewAccess && !paperLoading ? (
              <Box
                sx={{ width: "100vw", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <h2>You Do not have access to this roadmap. Request permission from the roadmap owner.</h2>
              </Box>
            ) : (
              <></>
            )}

            {hasViewAccess && !paperLoading ? (
              <Fade in={true} timeout={2000}>
                <div style={{ backgroundColor: "aliceblue", marginTop: "20px" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="roadmap difficulties">
                      {renderTabs()}
                      {boardLevels < 3 && hasEditAccess ? (
                        <Button onClick={updateLevel}>
                          <AddCircleOutlineIcon></AddCircleOutlineIcon>
                        </Button>
                      ) : null}
                    </Tabs>
                  </Box>
                  <Box style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                    <TabPanel value={value} index={0}>
                      {columns[0] ? (
                        columns[0]?.cards?.map((card) => (
                          <BAPdfCard
                            key={card.id}
                            card={card}
                            hasEditAccess={hasEditAccess}
                            isBigScreen={isBigScreen}
                            handleOpen={handleOpen}
                            setCardID={setCardID}
                            deleteTask={deleteTask}
                            id={id}
                          ></BAPdfCard>
                        ))
                      ) : (
                        <Typography variant="h5" component={"span"}>
                          No papers found
                        </Typography>
                      )}
                      {hasEditAccess ? (
                        <AddPaper boardId={boardId} level={1} refreshOnSuccess={getPapersdata} setNotify={setNotify} />
                      ) : (
                        <></>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {columns[1] ? (
                        columns[1].cards.map((card) => (
                          <BAPdfCard
                            key={card.id}
                            hasEditAccess={hasEditAccess}
                            card={card}
                            isBigScreen={isBigScreen}
                            handleOpen={handleOpen}
                            setCardID={setCardID}
                            deleteTask={deleteTask}
                            id={id}
                          ></BAPdfCard>
                        ))
                      ) : (
                        <Typography variant="h5" component={"span"}>
                          No papers found
                        </Typography>
                      )}
                      {hasEditAccess && (
                        <AddPaper boardId={boardId} level={2} refreshOnSuccess={getPapersdata} setNotify={setNotify} />
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      {columns[2] ? (
                        columns[2].cards.map((card) => (
                          <BAPdfCard
                            key={card.id}
                            hasEditAccess={hasEditAccess}
                            card={card}
                            isBigScreen={isBigScreen}
                            handleOpen={handleOpen}
                            setCardID={setCardID}
                            deleteTask={deleteTask}
                            id={id}
                          ></BAPdfCard>
                        ))
                      ) : (
                        <Typography variant="h5" component={"span"}>
                          No papers found
                        </Typography>
                      )}
                      {hasEditAccess && (
                        <AddPaper boardId={boardId} level={3} refreshOnSuccess={getPapersdata} setNotify={setNotify} />
                      )}
                    </TabPanel>
                  </Box>
                </div>
              </Fade>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-link">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component={"span"}>
            {expandPDFEdit ? (
              <Alert style={{ marginTop: "5px", marginBottom: "10px" }} severity={severity}>
                Uploaded Successfully: When saved PDF url will automatically update
              </Alert>
            ) : (
              <></>
            )}
            Edit paper details
          </Typography>
          <Typography id="modal-modal-link" style={{ marginTop: "2px", width: "100%" }} component={"span"}>
            <TextField
              variant="filled"
              style={{ width: "530px", marginTop: "10px" }}
              placeholder="Paper Name"
              value={paperName}
              name="paperName"
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => setPaperName(event.target.value)}
            />

            <TextField
              variant="filled"
              style={{ width: "530px", marginTop: "10px" }}
              placeholder="Paper Link"
              value={paperLink}
              name="paperLink"
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => setPaperLink(event.target.value)}
            />

            <Button
              variant="outlined"
              color="success"
              style={{ margin: "10px" }}
              onClick={(event) => handlePaperChange(cardID, paperName, paperLink)}
            >
              Save
            </Button>
            <Button variant="outlined" color="error" style={{ margin: "10px" }} onClick={handleClose}>
              Cancel
            </Button>
          </Typography>
          {/* 
          {isAdmin ? (
            <label htmlFor="upload-pdf-edit">
              <input
                style={{ display: "none" }}
                id="upload-pdf-edit"
                name="upload-pdf-edit"
                type="file"
                onChange={(e) => handlePDFSelectEdit(e)}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
                style={{
                  margin: "8px",
                  backgroundColor: "#536680",
                  borderRadius: "20px",
                  maxWidth: "150px",
                  maxHeight: "40px",
                }}
              >
                Upload PDF
              </Button>
            </label>
          ) : (
            <></>
          )} */}
        </Box>
      </Modal>
      {/* <SuggestedRoadmaps roadmap_id={boardId} /> */}
      <Footer />
      <ActionAlerts notify={notify} setNotify={setNotify} />
    </>
  );
};

export default BoardArea;
