import React, { useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  Modal,
  Paper,
  Rating,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Close,
  DeleteForeverOutlined,
  Edit,
  FileCopy as FileCopyIcon,
  Height,
  InsertPhoto as InsertPhotoIcon,
  Margin,
  Save,
  Star as StarIcon,
} from "@mui/icons-material";
import { Roadmap } from "../../../types/roadmap";
import { useAuthState } from "react-firebase-hooks/auth";
import { uploadPDF, auth, uploadThumbnail } from "../../../firebase";
import { apiLink, apiLink_classroom } from "../../../default";
import axios from "axios";
import PermissionDashboard from "./EditPermsModel";
import { useNavigate } from "react-router-dom";
import { RoutesPage } from "../../../service/config";

interface BoardAreaHeaderProps {
  roadmapData: Roadmap;
  hasEditAccess: boolean;
  setNotify: any;
  fetchBoard: any;
  setBoardId: any;
  // Add any required props here
}

const BoardAreaHeader: React.FC<BoardAreaHeaderProps> = (props) => {
  const [user, error] = useAuthState(auth);
  const [thumbnailURL, setThumbnailURL] = useState(props.roadmapData.imageURL);
  const [open, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState(-1);
  const [RemoveRoadmap, setRemoveRoadmap] = useState(false);
  const [openPermsModel, setOpenPermsModel] = useState(false);
  const handlePermsOpen = () => setOpenPermsModel(true);
  const handlePermsClose = () => setOpenPermsModel(false);
  const [backDropText, setBackDropText] = useState<string>("DO NOT REFRESH THE PAGE");
  const userEmail = localStorage.getItem("token");
  const boardId = props.roadmapData.uid;
  const [copyChipData, setCopyChipData] = useState(Object.values(props.roadmapData.tags));

  const [chipData, setChipData] = useState(Object.values(props.roadmapData.tags));
  const [chipInput, setChipInput] = useState("");
  const [openEditOptions, setOpenEditOptions] = useState(false);

  const [boardName, setBoardName] = useState(props.roadmapData.name);
  const [boardDescription, setBoardDescription] = useState(props.roadmapData.description);
  const [controlLabel, setControlLabel] = useState(true);
  const [fileEdit, setFileEdit] = useState<File>();

  const history = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchUserLearningList() {
      try {
        const token = await user?.getIdToken();
        const response = await fetch(`${apiLink}/user/learning_list`, {
          method: "GET",

          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const result = await response.json();
        result.data.learning_list.forEach((roadmap) => {
          if (roadmap.uid == boardId) {
            setRemoveRoadmap(true);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchUserLearningList();
  }, []);

  const handleCloseEdit = () => {
    setOpenEditOptions(false);
    setChipData(copyChipData);
  };

  const handleAddChipInput = (chipInput) => {
    let temp = [...chipData, chipInput];
    setChipData(temp);
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  function getAuthorName(authorUid) {
    const uidNameMap: { uid: string; name: string }[] = JSON.parse(localStorage.getItem("uidNameMap") || "");

    let author = "";
    uidNameMap.forEach((data) => {
      if (data.uid === authorUid) {
        author = data.name;
      }
    });
    return author;
  }

  function handleThumbnailSelect(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files?.[0];

    if (file) {
      setFileEdit(file);

      const reader = new FileReader();

      reader.onload = function (e) {
        setThumbnailURL(e.target?.result as string);
      };

      reader.readAsDataURL(file);
    }
  }

  const deleteRoadmap = async (boardId) => {
    let token = await user?.getIdToken();
    setLoading(true);
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };

    try {
      const res = await axios.delete(`${apiLink_classroom}/roadmap/${boardId}/`);
    } catch (error) {}

    try {
      const response = await axios.delete(`${apiLink}/roadmap/delete_roadmap/${boardId}`, {
        headers,
      });
      if (response.status === 200) {
        setLoading(false);

        props.setNotify({
          isOpen: true,
          message: "Roadmap Deleted you will be routed to the home page shortly",
          type: "success",
        });
        setTimeout(() => {
          history("/");
        }, 2500);
      } else {
        throw new Error("An error has occurred");
      }
    } catch (error) {
      setLoading(false);
      props.setNotify({
        isOpen: true,
        message: "Unable to delete roadmap",
        type: "error",
      });
    }
  };
  const cloneRoadmap = async (boardId) => {
    const token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };

    return axios
      .post(`${apiLink}/roadmap/clone`, {}, { params: { user_email: userEmail, roadmap_id: boardId }, headers })
      .then((response) => {
        if (response.status === 200) {
          props.setNotify({
            isOpen: true,
            message: "Roadmap cloned successfully",
            type: "success",
          });
          const res = {
            hasViewAccess: true,
            hasEditAccess: true,
          };
          history(`${RoutesPage.boards}/${response.data.clonedUID}`, { state: res });
          //   props.setBoardId(response.data.clonedUID);
          window.location.reload();

          //   props.fetchBoard(response.data.clonedUID);
        } else {
          props.setNotify({
            isOpen: true,
            message: "Unable to clone roadmap",
            type: "error",
          });
          throw new Error("An error has occurred");
        }
      });
  };

  const changeRating = async (newRating) => {
    if (newRating === 0 || props.hasEditAccess) {
      props.setNotify({
        isOpen: true,
        message: "You don't have permission to rate this roadmap",
        type: "error",
      });
      return;
    }

    const postData = {
      rating: newRating,
    };
    const token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    // console.log(postData);
    try {
      const response = await axios.post(`${apiLink}/roadmap/rating`, postData, {
        params: { roadmap_id: boardId },
        headers,
      });

      props.setNotify({
        isOpen: true,
        message: "Rating Updated",
        type: "success",
      });
      props.fetchBoard(boardId);
    } catch (error) {
      // You can pass the error message or a custom message into props.setNotify
      const errorMessage = error?.response?.data?.detail || "An error occurred";
      props.setNotify({
        isOpen: true,
        message: errorMessage,
        type: "error",
      });
    }
  };

  const updateTask = async (publicVal: boolean) => {
    setOpenEditOptions(false);
    setLoading(true);

    let bgImg = thumbnailURL;

    try {
      if (fileEdit) {
        bgImg = await uploadThumbnail(fileEdit, boardId);
      }
    } catch (error) {
      setLoading(false);

      props.setNotify({
        isOpen: true,
        message: "Error occured while uploading Background Img",
        type: "error",
      });
    }

    const token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };

    const payload = {
      name: boardName,
      description: boardDescription,
      rating: props.roadmapData.rating,
      author: props.roadmapData.author,
      imageURL: bgImg,
      tags: chipData,
      levels: props.roadmapData.levels,
      public: publicVal,
    };

    try {
      const response = await axios.put(`${apiLink}/roadmap/?roadmap_id=${boardId}`, payload, {
        params: { roadmap_id: boardId },
        headers,
      });
      setLoading(false);

      props.setNotify({
        isOpen: true,
        message: "Roadmap updated successfully",
        type: "success",
      });
      props.fetchBoard(boardId);
    } catch (error) {
      setLoading(false);

      props.setNotify({
        isOpen: true,
        message: "Error occured while updating roadmap",
        type: "error",
      });
    }
  };

  const deleteFromLearningList = async (boardId) => {
    const token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    return axios
      .delete(`${apiLink}/user/learning_list`, {
        params: {
          roadmap_id: boardId,
        },
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          props.setNotify({
            isOpen: true,
            message: "Successfully removed from Learning List",
            type: "success",
          });
          setRemoveRoadmap(false);
        } else {
          props.setNotify({
            isOpen: true,
            message: "Unable to remove from Learning list",
            type: "error",
          });
          throw new Error("An error has occurred");
        }
      });
  };

  const addToLearningList = async (boardId) => {
    const token = await user?.getIdToken();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    };
    return axios
      .post(
        `${apiLink}/user/learning_list`,
        {},
        {
          params: {
            roadmap_id: boardId,
          },
          headers,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          props.setNotify({
            isOpen: true,
            message: "Successfully added to your Learning list",
            type: "success",
          });
          setRemoveRoadmap(true);
        } else {
          props.setNotify({
            isOpen: true,
            message: "Error occured while adding to Learning list",
            type: "error",
          });
          throw new Error("An error has occurred");
        }
      });
  };

  return (
    <Box
      style={{
        padding: "20px",
        backgroundImage: `url(${thumbnailURL})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        boxShadow: "0 3000px rgba(0, 0, 0, 0.4) inset",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.tooltip + 1,
          flexDirection: "column",
          alignItems: "center",
        }}
        open={loading}
      >
        <h4>{backDropText}</h4>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box style={{ display: "flex" }}>
        <Box
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            marginBottom: "10px",
          }}
        >
          {openEditOptions ? (
            <TextField
              variant="filled"
              label="Edit Roadmap Name"
              fullWidth
              style={{
                marginBottom: "10px",
                color: "white",
                background: "rgb(232, 241, 250)",
              }}
              placeholder="Roadmap Name"
              value={boardName}
              onChange={(event) => setBoardName(event.target.value)}
            />
          ) : (
            <Typography
              variant="h5"
              sx={{
                width: "100%",
                fontWeight: "bold",
                marginBottom: "10px",
                color: "white",
                textShadow: "#000 1px 0 10px",
              }}
            >
              {boardName}
            </Typography>
          )}
          {openEditOptions ? (
            <TextField
              variant="filled"
              label="Edit Roadmap Description"
              multiline={true}
              fullWidth
              sx={{
                color: "white",
                background: "rgb(232, 241, 250)",
              }}
              placeholder="Roadmap Description"
              value={boardDescription}
              onChange={(event) => setBoardDescription(event.target.value)}
            />
          ) : (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "white",
                textShadow: "#000 1px 0 10px",
              }}
            >
              {boardDescription}
            </Typography>
          )}
        </Box>

        {props.hasEditAccess && (
          <>
            {" "}
            {openEditOptions ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Tooltip title="Cancel Edit" placement="top">
                  <Button
                    variant="contained"
                    style={{
                      margin: "8px",
                      backgroundColor: "#536680",
                      borderRadius: "20px",
                      width: "150px",
                      maxHeight: "40px",
                    }}
                    onClick={handleCloseEdit}
                    startIcon={<Close />}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Upload Background Img for Roadmap" placement="top">
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={(e) => handleThumbnailSelect(e)}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      style={{
                        margin: "8px",
                        backgroundColor: "#536680",
                        borderRadius: "20px",
                        width: "150px",
                        maxHeight: "40px",
                      }}
                      startIcon={<InsertPhotoIcon />}
                    >
                      Thumbnail
                    </Button>
                  </label>
                </Tooltip>

                <Tooltip title="Click to save changes made in roadmap Name, Description & Tags" placement="top">
                  <Button
                    variant="contained"
                    style={{
                      margin: "8px",
                      backgroundColor: "#536680",
                      borderRadius: "20px",
                      width: "150px",
                      maxHeight: "40px",
                    }}
                    startIcon={<Save />}
                    onClick={() => updateTask(controlLabel)}
                  >
                    Save
                  </Button>
                </Tooltip>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Tooltip title="Click to Edit User Permsions" placement="top">
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: "auto",
                      margin: "3px",
                      backgroundColor: "#536680",
                      borderRadius: "20px",
                      width: "100px",
                      maxHeight: "40px",
                    }}
                    startIcon={<Edit />}
                    onClick={() => handlePermsOpen()}
                  >
                    Perms
                  </Button>
                </Tooltip>
                <Tooltip title="Click to start making changes in roadmap Name, Description & Tags" placement="top">
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: "auto",
                      backgroundColor: "#536680",
                      borderRadius: "20px",
                      margin: "3px",
                      width: "100px",
                      maxHeight: "40px",
                    }}
                    startIcon={<Edit />}
                    onClick={() => setOpenEditOptions(!openEditOptions)}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Box>
            )}
          </>
        )}
        <Box style={{ marginBottom: "10px" }}></Box>
      </Box>

      <Box
        style={{
          display: "flex",
          marginTop: "2px",
          flexDirection: "column",
        }}
      >
        <List
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            padding: 0,
          }}
        >
          {chipData.map((data, index) => {
            return (
              <ListItem key={index} sx={{ width: "7rem", padding: "0", marginRight: "1rem", marginBottom: "1rem" }}>
                {props.hasEditAccess && openEditOptions ? (
                  <Chip
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    label={data}
                    onDelete={handleDelete(data)}
                  />
                ) : (
                  <Chip
                    style={{
                      width: "100%",

                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    label={data}
                  />
                )}
              </ListItem>
            );
          })}
        </List>
        {props.hasEditAccess && openEditOptions && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              style={{
                width: 300,
                background: "rgb(232, 241, 250)",
                marginTop: "1rem",
                marginRight: "1rem",
                marginBottom: ".5rem",
              }}
              placeholder="Tags"
              value={chipInput}
              onKeyDown={(e) => {
                if (e.key === " ") handleAddChipInput(chipInput);
              }}
              onChange={(event) => setChipInput(event.target.value)}
            />
            <Tooltip title="Click this button or press spacebar to enter a tag" placement="top" arrow>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#3d5a80",
                  borderRadius: "20px",
                  maxWidth: "100px",
                  maxHeight: "40px",
                  // margin: "5px",
                }}
                onClick={() => handleAddChipInput(chipInput)}
              >
                Add Tag
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: 0.5,
        }}
      >
        <Typography variant="h6" style={{ color: "white" }}>
          {props.roadmapData.rating}&nbsp;
        </Typography>
        <Rating
          name="hover-feedback"
          value={props.roadmapData.rating}
          precision={0.5}
          size="small"
          readOnly={props.hasEditAccess}
          onChange={(event, newValue) => {
            console.log(newValue);
            changeRating(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon fontSize="inherit" />}
        />
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          marginBottom: "15px",
          margin: 0.5,
        }}
      >
        <Typography fontWeight={"bold"} style={{ color: "white" }}>
          By&nbsp;
        </Typography>
        <Typography variant="h6" style={{ color: "white" }}>
          {getAuthorName(props.roadmapData.author) || props.roadmapData?.author}
        </Typography>
      </Box>
      <Box>
        {RemoveRoadmap ? (
          <Tooltip title="This action will remove the roadmap from your learning list" placement="top" arrow>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#3d5a80",
                borderRadius: "20px",
                margin: 0.5,
              }}
              startIcon={<DeleteForeverOutlined />}
              color="primary"
              onClick={() => deleteFromLearningList(boardId)}
            >
              Remove from learning list
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="This action will add the roadmap to your learning list" placement="top" arrow>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#3d5a80",
                borderRadius: "20px",
                margin: "5px",
              }}
              startIcon={<AddCircleOutlineIcon />}
              color="primary"
              onClick={() => addToLearningList(boardId)}
            >
              Add to my learning list
            </Button>
          </Tooltip>
        )}
        <Tooltip title="This action will clone the current roadmap to your roadmap" placement="top" arrow>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3d5a80",
              borderRadius: "20px",
              margin: "5px",
            }}
            startIcon={<FileCopyIcon />}
            color="primary"
            onClick={() => cloneRoadmap(boardId)}
          >
            Clone
          </Button>
        </Tooltip>
        {props.hasEditAccess && (
          <>
            <Tooltip title="This action will delete the roadmap" placement="top" arrow>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#3d5a80",
                  borderRadius: "20px",
                  margin: "5px",
                }}
                startIcon={<DeleteForeverOutlined />}
                color="primary"
                onClick={() => deleteRoadmap(boardId)}
              >
                Delete Roadmap
              </Button>
            </Tooltip>
          </>
        )}
      </Box>

      <Modal
        open={openPermsModel}
        onClose={handlePermsClose}
        aria-labelledby="permission-modal"
        aria-describedby="permission-modal-edit-viewing-perms"
      >
        <div>
          <PermissionDashboard roadmap_id={boardId}></PermissionDashboard>
        </div>
      </Modal>
    </Box>
  );
};

export default BoardAreaHeader;
