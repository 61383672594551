import * as React from "react";
import { useNavigate } from "react-router-dom";
import { RoutesPage } from "../../service/config";
import { getIllustration, getBackground, getBackgrounds } from "../../utils";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function CardTag({ tag }) {
  const navigate = useNavigate();

  const openRoadmap = (tag) => {
    navigate(`/search/?filter=tag&q=${tag.name}`);
  };

  return (
    <Card sx={{ width: 280, margin: "10px", marginBottom: "30px" }} onClick={() => openRoadmap(tag)}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={getIllustration(Math.floor(Math.random() * 3))}
          alt=""
          style={{ backgroundColor: `${getBackgrounds(Math.floor(Math.random() * 5))}` }}
          // style={{ backgroundColor: 'red' }}
        />
        <CardContent style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "bold" }}>
            {tag?.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
