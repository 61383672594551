import React, { useState, useEffect, useRef } from "react";
import { Box, Toolbar, Button, Modal, Typography } from "@mui/material";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { apiLink, apiLink_classroom } from "../../../default";
import { pdfjs, Document, Page } from "react-pdf";
import { auth } from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export function ViewPdf(props) {
  const [user, error] = useAuthState(auth);
  const navigate = useNavigate();
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const pgNum = Number(searchParams.get("page"));
  let { uid } = useParams();
  const [viewAccess, setViewAccess] = useState(true);
  const [scale, setScale] = useState(1.0);
  const timeIntervalForData = 5000;
  const [papersData, setPapersData]: any = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paperLink, setPaperLink] = useState("");

  const modalStyle = {};

  const convertHexUidToNormalUid = (hexUid: string): string => {
    // Remove the "0x" prefix from the hexUid
    const strippedHexUid = hexUid.replace("0x", "");
    // Convert the strippedHexUid to a normal uid by adding hyphens
    const normalUid = `${strippedHexUid.substring(0, 8)}-${strippedHexUid.substring(8, 12)}-${strippedHexUid.substring(
      12,
      16
    )}-${strippedHexUid.substring(16, 20)}-${strippedHexUid.substring(20)}`;

    return normalUid;
  };
  const handleModalOpen = () => setOpenModal(!openModal);

  useEffect(() => {
    const handleVisibilityChange = () => {};

    document.addEventListener("visibilitychange", handleVisibilityChange);
    if (!paperLink) {
      getPaperData();
    }

    const interval = setInterval(() => {
      if (!document.hasFocus()) {
        handleModalOpen();
      }
      if (
        document.visibilityState === "visible" &&
        localStorage.getItem("student_id") &&
        document.hasFocus() &&
        !openModal
      ) {
        updatePaperData();
      }
    }, timeIntervalForData);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(interval);
    };
  }, [currentPage, document.visibilityState, numPages]);

  const updatePaperData = async () => {
    if (pgNum !== 0 && numPages !== 0) {
      console.log(pgNum);
      const paperDataArr = {
        page_num: pgNum,
        time_spent: timeIntervalForData,
      };

      const pdfUID = convertHexUidToNormalUid(id ?? "");
      const token = await user?.getIdToken();
      const body = {
        student_id: localStorage.getItem("student_id"),
        student_name: localStorage.getItem("student_name"),
        roadmap_id: convertHexUidToNormalUid(uid ?? ""),
        assignment_id: localStorage.getItem("assignment_id"),
        pg_num: numPages,
        pdf_name: papersData,
        pdf_pages: paperDataArr,
      };

      const bdy = JSON.stringify(body);
      try {
        const response = await fetch(`${apiLink_classroom}/pdf-page/update/${pdfUID}/`, {
          method: "PUT",
          body: bdy,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);
      } catch (error) {}
    }
  };

  function formatUrl(pdfUrl) {
    // Ensure the URL starts with 'https://'
    if (!pdfUrl.startsWith("https://")) {
      pdfUrl = "https://" + pdfUrl;
    }

    // Replace spaces with %20 for URL encoding
    return pdfUrl.replace(/ /g, "%20");
  }

  const getPaperData = async () => {
    const token = await user?.getIdToken();

    try {
      const response = await fetch(`${apiLink}/paper/${id}?roadmap_id=${uid}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const json = await response.json();

      setPaperLink(formatUrl(json.link));
      setPapersData(json.name);
    } catch (error) {
      console.log(error);
    }
  };

  const routeToRoadmap = () => {
    navigate(`/boards/${uid}`);
  };

  function onNextPageButtonClick(event, page: number) {
    setSearchParams(`page=${page}`);
    setCurrentPage(page);
  }

  async function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    // await createPdfPagesDictMap(numPages);
  }

  function setPDFScale(isScaleUp: boolean) {
    if (isScaleUp) {
      if (scale + 1.0 <= 5.0) {
        setScale(scale + 1.0);
      }
    } else {
      if (scale - 1.0 >= 1.0) {
        setScale(scale - 1.0);
      }
    }
    console.log(scale);
  }

  return (
    <>
      <Box
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "10rem",
          backgroundColor: "aliceblue",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <Button variant="contained" onClick={routeToRoadmap}>
            Go Back To Roadmap
          </Button>
          <span
            onClick={() => setPDFScale(false)}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "2rem" }}
          >
            Zoom Out <ZoomOutIcon style={{ height: "2rem", width: "2rem", marginLeft: ".5rem" }}></ZoomOutIcon>
          </span>
          <span
            onClick={() => setPDFScale(true)}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: ".5rem" }}
          >
            Zoom In <ZoomInIcon style={{ height: "2rem", width: "2rem", marginLeft: ".5rem" }}></ZoomInIcon>
          </span>
        </div>

        <Box
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {viewAccess ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Document file={paperLink} onLoadSuccess={onDocumentLoadSuccess}>
                <Page renderTextLayer={true} scale={scale} pageNumber={pgNum} />
              </Document>
              <Box
                style={{
                  padding: "2rem",
                }}
              >
                Page {pgNum} of {numPages}
                <Pagination
                  style={{
                    marginTop: "2rem",
                  }}
                  count={numPages}
                  defaultPage={pgNum}
                  showFirstButton
                  showLastButton
                  onChange={onNextPageButtonClick}
                  variant="outlined"
                  color="primary"
                />
              </Box>
            </div>
          ) : (
            <h1>Access Denied</h1>
          )}
        </Box>
      </Box>

      <Modal
        aria-labelledby="modal"
        aria-describedby="modal to check if user is still presently on the page, press yes to continue reading"
        open={openModal}
        onClose={handleModalOpen}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "45vw",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Typography id="modal-title" style={{ textAlign: "center", fontSize: "2vh" }} variant="h6" component="h2">
            Are you still reading?
          </Typography>
          <Box
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              style={{
                fontSize: "1.5vh",
                backgroundColor: "green",
                margin: "1rem",
              }}
              onClick={handleModalOpen}
            >
              Continue Reading
            </Button>
            <Button
              variant="contained"
              style={{
                fontSize: "1.5vh",
                backgroundColor: "red",
                margin: "1rem",
              }}
              onClick={routeToRoadmap}
            >
              Go Back To Roadmap
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
