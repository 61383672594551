import * as React from "react";
import { useNavigate } from "react-router-dom";
import { RoutesPage } from "../../service/config";
import { getIllustration, getBackground, getBackgrounds } from "../../utils";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { apiLink } from "../../default";
const img = require("../../assets/illustrations/computer-vision-and-image-processing.jpeg");

export default function CardTag({ roadmap, assignment }) {
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);
  const [user, error] = useAuthState(auth);

  const openRoadmap = async (roadmap) => {
    try {
      let token = await user?.getIdToken();
      let roadmapId = roadmap.roadmap_id.replace(/-/g, "");
      if (roadmap.alasia_roadmap_id) {
        roadmapId = roadmap.alasia_roadmap_id.replace(/-/g, "");
      }
      console.log(roadmapId);
      const response = await fetch(`${apiLink}/roadmap/info/${roadmapId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const res = {
        hasEditAccess: false,
        hasViewAccess: true,
        isStudent: true,
      };
      localStorage.setItem("assignment_id", assignment.assignment_id);

      setData(data);
      navigate(`/boards/${roadmapId}`, { state: res });
    } catch (error) {
      console.log(error);
    }
    // history(`/search/?filter=${roadmap.roadmap_id}`);
    // history(`/boards/${roadmap.roadmap_id}`);
    // history.push({
    //     pathname: '/search',
    //     search: `${roadmap.name}`
    // });
  };

  return (
    <Card sx={{ width: 280, margin: "10px", marginBottom: "30px" }} onClick={() => openRoadmap(roadmap)}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={getIllustration(Math.floor(Math.random() * 3))}
          alt=""
          style={{ backgroundColor: `${getBackgrounds(Math.floor(Math.random() * 5))}` }}
          // style={{ backgroundColor: 'red' }}
        />
        <CardContent style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "bold" }}>
            {roadmap?.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
