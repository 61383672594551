import { Toolbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import "./ClassRoom.css";
import AssignmentTray from "./AssignmentTray";
import { apiLink_classroom } from "../../default";

export default function ClassRoom() {
  const [classroomData, setClassroomData] = React.useState<any>();
  const location = useLocation();

  async function fetchClassroom() {
    const response = await fetch(`${apiLink_classroom}/${location.pathname}/`);
    const result = await response.json();
    setClassroomData(result);
  }

  useEffect(() => {
    fetchClassroom();
  }, []);

  return (
    <div className="classroom">
      <Toolbar />
      <div className="classroom-header"></div>
      <div className="classroom-brand">
        <Typography variant="h5" fontWeight="bold">
          {classroomData?.data?.subject}
        </Typography>
        <Typography variant="h6">Instructor: {classroomData?.teachers[0].name}</Typography>
      </div>
      <div className="classroom-body">
        <>
          <Typography variant="h5" fontWeight="bold">
            Assignments
          </Typography>
          {/* <FormatListBulletedIcon />
                    <GridViewIcon /> */}
        </>
        {classroomData?.assignments.map((assignment) => (
          <AssignmentTray key={assignment.assignment_id} assignment={assignment} />
        ))}
      </div>
    </div>
  );
}
