import React from "react";
import "./AssignmentTray.css";
import Typography from "@mui/material/Typography";
import CardForClassroom from "../../components/Card/CardForClassroom";

function AssignmentTray({ assignment }) {
  const [toggleView, setToggleView] = React.useState(true);

  return (
    <div className="assignmentTray">
      <div className="assignment-header">
        <div className="assignment-header-name">
          <Typography fontWeight="bold">{assignment?.name}</Typography>
        </div>
        <div className="assignment-header-dueDate">
          <Typography fontWeight="bold">Due Date: {assignment?.due_date[0]?.classroom_due_date}</Typography>
        </div>
      </div>
      <div className="assignment-body">
        {assignment?.roadmaps?.map((item) => (
          <>
            <div key={item.roadmap_id} className={toggleView ? "list" : "grid"}>
              {/* <Typography fontWeight='bold'>{item.name}</Typography> */}
              <CardForClassroom roadmap={item} assignment={assignment} />
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default AssignmentTray;
