import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grow,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material/";
import Box from "@mui/material/Box";
import { apiLink } from "../../default";
import React, { ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginBlock.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../firebase";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";

export const LoginBlock = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const [errorInEmailId, setErrorInEmailId] = useState(false);
  const [errorInPassword, setErrorInPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  async function fetchUserPerms(token: string) {
    try {
      const response = await fetch(`${apiLink}/user/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async () => {
    const response = await logInWithEmailAndPassword(email, password, navigate);
    if (response) {
      const token = await response.user.getIdToken();
      const perms = await fetchUserPerms(token);
      localStorage.setItem("isAdmin", `${perms.has_admin_control}`);
      localStorage.setItem("userUID", `${perms.firebase_id}`);
    }

    // if (error === "FirebaseError: Firebase: Error (auth/user-not-found).") {
    //     setErrorInEmailId(true);
    // } else if (error === "FirebaseError: Firebase: Error (auth/wrong-password).") {
    //     setErrorInPassword(true);
    // }
  };

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) {
    }
  }, [user, loading]);

  return (
    <>
      <Toolbar />
      <Grow in={true} timeout={500}>
        <Box
          style={{
            width: "100%",
            height: "83vh",
            paddingTop: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card variant="outlined" className="CreateBoardCard" style={{ maxWidth: "600px", maxHeight: "800px" }}>
            <CardHeader className="LoginHeader" title="Login" titleTypographyProps={{ variant: "h4" }} />
            <CardContent className="LoginContent">
              <TextField
                tabIndex={1}
                error={errorInEmailId}
                className="LoginTextField"
                required
                id="filled-required"
                label="Email"
                placeholder="Enter Email"
                helperText={errorInEmailId ? "Invalid E-Mail" : ""}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setemail(event.target.value);
                  setErrorInEmailId(false);
                }}
              />
              <TextField
                tabIndex={2}
                error={errorInPassword}
                type={showPassword ? "text" : "password"}
                className="LoginTextField"
                required
                id="filled-required"
                label="Password"
                placeholder="Enter Password"
                variant="outlined"
                helperText={
                  <Link href="forgotPassword" underline="hover">
                    {"Forgot Password?"}
                  </Link>
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                  setErrorInPassword(false);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={3}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </CardContent>

            <CardActions className="LoginAction">
              <Button
                tabIndex={4}
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                className="LoginButton"
              >
                Login
              </Button>
            </CardActions>

            <CardActions className="LoginAction">
              <Button
                tabIndex={5}
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => signInWithGoogle()}
                className="LoginButton"
              >
                Login with Google
              </Button>
            </CardActions>

            <CardContent className="LoginContent">
              Don't have an Account?{" "}
              <Link href="Register" tabIndex={6}>
                Register here!
              </Link>
            </CardContent>
          </Card>
        </Box>
      </Grow>
    </>
  );
};
