import { useNavigate } from "react-router-dom";
import { RoutesPage } from "../../service/config";
import { getIllustration, getBackgrounds } from "../../utils";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Rating from "@mui/material/Rating";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function CardMyAssignment({ classroom }) {
  const navigate = useNavigate();

  const uidNameMap: { uid: string; name: string }[] = JSON.parse(localStorage.getItem("uidNameMap") || "");

  function getAuthorName(authorUid) {
    let author = "";
    uidNameMap.forEach((data) => {
      if (data.uid === authorUid) {
        author = data.name;
      }
    });
    return author;
  }

  const openRoadmap = (board) => {
    navigate(`${RoutesPage.boards}/${board.uid}`, board);
  };

  function handleClick(key) {
    navigate(`classroom/${key}`);
  }

  return (
    <Card sx={{ width: 280, height: 280, margin: "10px" }}>
      <CardActionArea onClick={() => handleClick(classroom.classroom_id)}>
        <CardMedia
          component="img"
          height="140"
          image={getIllustration(Math.floor(Math.random() * 3))}
          alt={classroom.className}
          style={{ backgroundColor: `${getBackgrounds(Math.floor(Math.random() * 5))}` }}
        />
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Typography gutterBottom component="span" style={{ fontWeight: "bold" }}>
            Class: {classroom?.class_name}
          </Typography>
          <Typography gutterBottom component="span" style={{ fontWeight: "bold" }}>
            Subject: {classroom?.subject}
          </Typography>
          <Typography gutterBottom component="span" style={{ fontWeight: "bold" }}>
            Section: {classroom?.section}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
